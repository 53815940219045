<template>
  <div>
    <div class="container mb-5 mt-4 g-lg-0 ">
      <div class="row g-0">
        <div class="col">
          <nav class="navbar navbar-expand-lg" :class="{ 'fixed-top': isNavbarFixed }">
            <div class="container-fluid g-0 ms-2">
              <a class="navbar-brand align-self-center d-lg-none d-block nav-logo" href="/">
                <img src="/images/luxury-portfolio-white.png" style="width: 150px;" />
                <img src="/images/luxury-portfolio.png" style="width: 150px;" />
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse justify-content-lg-center" id="navbarNavDropdown">
                <ul class="navbar-nav justify-content-center">
                  <li class="nav-item d-lg-none d-block align-self-center">
                    <a class="nav-link active" href="/">Home</a>
                  </li>
                  <li class="nav-item dropdown align-self-center">
                    <a class="nav-link dropdown-toggle" href="/about" role="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      About
                    </a>
                    <ul class="dropdown-menu">
                      <li><router-link to="/about" class="dropdown-item"><strong>Chas Everitt Luxury</strong></router-link></li>
                      <li><router-link to="/agents" class="dropdown-item">Our team</router-link></li>
                      <li><router-link to="/branches" class="dropdown-item">Our branches</router-link></li>
                      <li><router-link to="/asset-management" class="dropdown-item">Asset management</router-link></li>
                      <li><router-link to="/currencies-direct" class="dropdown-item">Currency management</router-link></li>
                    </ul>
                  </li>
                  <!-- <li class="nav-item align-self-center">
                    <a class="nav-link" href="/about">About</a>
                  </li> -->
                  <li class="nav-item dropdown align-self-center">
                    <a class="nav-link dropdown-toggle" href="/portfolio" role="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Properties
                    </a>
                    <ul class="dropdown-menu">
                      <li><router-link to="/portfolio" @click.native="$store.state.search.tags.buy = true" class="dropdown-item"><strong>Buy</strong></router-link></li>
                      <li><router-link to="/rentals" @click.native="$store.state.search.tags.rent = true" class="dropdown-item"><strong>Rent</strong></router-link></li>
                      <li><router-link to="/portfolio" @click.native="$store.state.search.tags.inland = true" class="dropdown-item">Inland</router-link></li>
                      <li><router-link to="/portfolio" @click.native="$store.state.search.tags.coastal = true" class="dropdown-item">Coastal</router-link></li>
                      <li><router-link to="/portfolio" @click.native="$store.state.search.tags.countryside = true" class="dropdown-item">Countryside</router-link></li>
                      <li><router-link to="/portfolio" @click.native="$store.state.search.tags.city = true" class="dropdown-item">City</router-link></li>
                    </ul>
                  </li>
                  <li class="nav-item w-50 d-lg-block d-none logo-img">
                    <center>
                      <a class="navbar-brand " href="/">
                        <img src="/images/luxury-portfolio.png" style="width: 150px;"  alt="Chas Everitt Luxury logo"/>
                        <img src="/images/luxury-portfolio-white.png" style="width: 150px;"  alt="Chas Everitt Luxury logo"/>
                      </a>
                    </center>
                  </li>
                  <li class="nav-item align-self-center">
                    <a class="nav-link" href="/lifestyle">Lifestyle</a>
                  </li>
                  <li class="nav-item mb-md-0 mb-2 align-self-center">
                    <a class="nav-link" href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isNavbarFixed: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 50) {
        this.isNavbarFixed = true;
      } else {
        this.isNavbarFixed = false;
      }
    },
  },
};
</script>





